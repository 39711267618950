import * as React from "react"
import Contents from "../layouts/components/Contents";
import { Seo } from "../components";
import { useIntl } from "gatsby-plugin-intl"

export default function NotFoundPage() {

  const intl = useIntl()

  return (
    <Contents is404={true}>
      <Seo title={intl.formatMessage({ id: "404-t" })} />

      <main className="bg-img not-found">
        <div className="wrap">
          <h1>{intl.formatMessage({ id: "404-h" })}</h1>
        </div>
      </main>
    </Contents>
  )
}